<template>
  <a-row :gutter="8">
    <a-col flex="auto">
      <a-card :bodyStyle="{ padding: 0 }">
        <template #title>
          <a-form :model="otherSearchForm" layout="inline">
            <a-form-item style="width: 13%">
              <a-input v-model:value="otherSearchForm.orderId" placeholder="合同号" />
            </a-form-item>
            <a-form-item style="width: 13%">
              <a-input v-model:value="otherSearchForm.carNo" placeholder="车架号" />
            </a-form-item>
            <a-form-item style="width: 13%">
              <a-input v-model:value="otherSearchForm.feeName" placeholder="费用名称" />
            </a-form-item>
            <a-form-item style="width: 27%">
              <a-range-picker v-model:value="otherSearchForm.timeLimit" style="width:100%" format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD" :showTime="false" :placeholder="['起运时间始', '起运时间止']" />
            </a-form-item>
            <a-form-item style="width: 13%">
              <a-input style="width: 100%" v-model:value="otherSearchForm.startAddress" placeholder="路线">
                <template #prefix>始</template>
              </a-input>
            </a-form-item>
            <a-form-item style="width: 13%">
              <a-input style="width: 100%" v-model:value="otherSearchForm.endAddress" placeholder="路线">
                <template #prefix>终</template>
              </a-input>
            </a-form-item>
            <a-form-item style="width: 13%">
              <a-space>
                <a-button type="primary" @click="loadOrderPage">查询</a-button>
                <a-button @click="resetSearch" :disabled="!activeKey">重置</a-button>
                <a-button danger @click="settlement" :disabled="orderIds.length < 1">申请结算</a-button>
                <!-- <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
                    <UpOutlined v-if="open" />
                    <DownOutlined v-else />
                  </a-button> -->
              </a-space>
            </a-form-item>
          </a-form>
          <a-tag color="blue" class="select-table-info">
            <template #icon>
              <InfoCircleFilled />
            </template>
            已选择 <strong>{{ orderIds.length }}</strong> 项
            附加费总计 <strong>{{ activeSettlementAmt }}</strong>
            <a @click="orderIds = []">清空选择</a>
          </a-tag>
        </template>

        <a-table rowKey="id" :dataSource="feeList" :row-selection="{ selectedRowKeys: orderIds, onChange: selectChange }"
          :loading="loading" :pagination="false">
          <a-table-column :width="160" title="订单号" data-index="orderId" />
          <a-table-column :width="120" title="小车车牌" data-index="carNo" />
          <a-table-column :width="120" title="起运地" data-index="startAddress" />
          <a-table-column :width="120" title="目的地" data-index="endAddress" />
          <a-table-column :width="100" title="费用名称" data-index="feeName" />
          <a-table-column :width="100" title="备注" data-index="remark" />
          <a-table-column :width="80" title="可申请金额" data-index="settableAmt" />
          <a-table-column :width="80" title="已结" data-index="settledAmt" />
          <a-table-column :width="120" title="可结" data-index="settableAmt">
            <template #default="{ text, index }">
              <div>
                <div v-if="editableData[index]">
                  <a-input v-model:value="editableData[index].settlementAmt" @pressEnter="save(index)" />
                  <CheckOutlined @click="save(index)" />
                </div>
                <div v-else>
                  {{ text || ' ' }}
                  <EditOutlined @click="edit(index)" />
                </div>
              </div>
            </template>
          </a-table-column>
          <a-table-column :width="120" title="起运时间" data-index="startTime" />
        </a-table>
      </a-card>
    </a-col>
  </a-row>
  <a-modal v-model:visible="visible" width="40%" destroyOnClose>
    <template #title>
      <div>
        <span style="color:#344563;font-size:16px">付款申请</span>
        <span style="margin-left:10px;color:#6B778C;font-size:14px">付款总金额：</span>
        <span style="color:#6B778C;font-size:14px">{{ activeSettlementAmt }}元</span>
      </div>
    </template>
    <component :is="flowForm" :flowType="4" :amount="activeSettlementAmt" :settlementInfo="settlementInfo"
      @ok="applySuccess" />
  </a-modal>
</template>
<script>
import { page } from '@/api/crm/customer'
import { billAdditionalFee } from '@/api/trans/finance/bill'

import { reactive, onMounted, toRefs, watch } from 'vue'
import { UpOutlined, DownOutlined, InfoCircleFilled, EditOutlined, CheckOutlined } from '@ant-design/icons-vue'
import { Form, Modal } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { numberValidate } from '@/utils/validate'
import Payment from './components/Payment.vue'

export default {
  setup () {
    const otherSearchForm = reactive({
      orderId: '',
      carNo: '',
      timeLimit: [],
      startAddress: '',
      endAddress: '',
      feeName: ''
    })

    const state = reactive({
      customer: '',
      customers: [],
      loadingMore: false,
      searchKey: '',
      customerOagination: {},
      activeKey: '',

      orderPagination: {},
      feeList: [],
      orderIds: [],
      open: false,
      loading: false,
      activeSettlementAmt: 0,
      settlementInfo: { orderSettlementIds: [] },

      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ],
      visible: false

    })
    const flowForm = Payment

    const editableData = reactive({})

    const edit = key => {
      editableData[key] = cloneDeep(state.feeList.filter((item, index) => key === index)[0])
    }

    const save = key => {
      if (!numberValidate.test(editableData[key].settlementAmt)) {
        Modal.error({ content: '数字格式必须为正整数' })
      } else if (Number(editableData[key].settlementAmt) > Number(editableData[key].settableAmt)) {
        Modal.error({ content: `金额不能大于${editableData[key].settableAmt}` })
      } else {
        state.feeList[key] = editableData[key]
        delete editableData[key]
      }
    }
    const useForm = Form.useForm
    const { resetFields } = useForm(otherSearchForm, {})

    watch(() => [state.feeList, state.orderIds], ([feeList, orderIds], [prevOrderList, prevOrderIds]) => {
      if (feeList && orderIds) {
        state.settlementInfo.orderSettlementIds = []
        state.activeSettlementAmt = 0
        feeList.forEach(item => {
          if (orderIds.includes(item.id)) {
            state.activeSettlementAmt += Number(item.settableAmt)
            state.settlementInfo.orderSettlementIds.push({ id: item.id, applyAmt: item.settableAmt })
          }
        })
      }
    }, { deep: true })

    const loadData = () => {
      state.loadingMore = true
      page({
        name: state.searchKey,
        current: state.customerOagination.current + 1,
        size: state.customerOagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.customers.push(...res.data.records)
          state.customerOagination.current = res.data.current
          state.customerOagination.pageSize = res.data.size
          state.customerOagination.total = res.data.total
        }
        state.loadingMore = false
      })
    }
    onMounted(loadData)

    const searchData = () => {
      state.customers = []
      state.customerOagination.current = 0
      loadData()
    }

    const changeKey = key => {
      state.customer = state.customers.find(res => res.id === key).name
      state.orderIds = []
      state.orderCheckedList = []
      state.feeList = []
      state.orderPagination = {}
      loadOrderPage()
    }

    const loadOrderPage = () => {
      billAdditionalFee({ ...otherSearchForm }).then(res => {
        if (res.code === 10000) {
          state.feeList = res.data.map(item => {
            return { ...item, settlementAmt: item.settableAmt }
          })
        } else {
          state.feeList = []
        }
      })
    }

    const orderSearchData = () => {
      state.orderCheckedList = []
      state.feeList = []
      state.orderPagination = {}
      loadOrderPage()
    }

    const resetSearch = () => {
      state.orderCheckedList = []
      state.feeList = []
      state.orderPagination = {}
      resetFields()
      loadOrderPage()
    }

    const selectChange = (selectedRowKeys, selectedRows) => {
      state.orderIds = selectedRowKeys
    }

    const settlement = () => {
      state.visible = true
    }

    const applySuccess = () => {
      state.visible = false
      state.activeSettlementAmt = 0
      loadOrderPage()
    }

    return {
      flowForm,
      otherSearchForm,
      ...toRefs(state),
      searchData,
      loadData,
      changeKey,
      loadOrderPage,
      orderSearchData,
      resetSearch,
      selectChange,
      editableData,
      edit,
      save,
      settlement,
      applySuccess
    }
  },
  components: {
    UpOutlined,
    DownOutlined,
    InfoCircleFilled,
    EditOutlined,
    CheckOutlined
  }
}
</script>
<style lang="less" scoped>
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
  // background-color: #e9f4ff;
}

.ant-tabs .ant-tabs-left-bar {
  float: unset;

  .ant-tabs-tab {
    border-bottom: 1px solid #f0f0f0;
    margin: 0;

    &:hover {
      background-color: #f0f0f0;
    }

    .tab-left {
      width: 100px;
      float: left;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.select-table-info {
  width: 100%;
  margin-top: 16px;
  padding: 8px 24px 8px 16px;
  font-size: 14px;

  a {
    margin-left: 16px;
  }
}

.ant-form-item {
  margin-bottom: 4px;
}
</style>
